<template>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="bg-white shadow sm:rounded-lg">
        <div class="px-4 py-5 sm:p-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900 text-left">
                404 Page Not Found
            </h3>
            <!--            <div class="mt-2 max-w-xl text-sm text-gray-500">-->
            <!--                <p class="text-left">-->
            <!--                    Lorem ipsum dolor sit amet consectetur adipisicing elit.-->
            <!--                    Commodi, totam at reprehenderit maxime aut beatae ad.-->
            <!--                </p>-->
            <!--            </div>-->
            <!--            <div class="mt-3 text-sm text-left">-->
            <!--                Learn more about our CI features-->
            <!--                <span aria-hidden="true">&rarr;</span>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "PageNotFound"
}
</script>

<style scoped></style>
